<template>
  <div>
    <header-page></header-page>
    <section class="jumbotron text-center">
      <div class="container">
        <h1>Lista compilazioni</h1>
        <p class="lead text-muted">
          Storico compilazioni effettutate.
        </p>
        <p class="alert alert-secondary" v-if="!builds || builds.length===0">
          <b>Nessuna compilazione presente.</b>
        </p>
        <p>
          <button @click="refresh()" class="btn btn-success">Aggiorna</button>
        </p>
        <div class="row">
          <div class="col-12">
            <table class="table" v-if="builds && builds.length>0">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Data</th>
                  <th scope="col">Stato</th>
                  <th scope="col">Azioni</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="build in builds" :key="build.id">
                  <th scope="row">
                    <router-link :to="'/detail/'+build.build_id" class="btn btn-outline-dark my-2 my-sm-0">{{ build.build_id }}</router-link>
                  </th>
                  <td>{{ build.date }}</td>
                  <td>
                    <div class="badge p-2 bg-success text-light" v-if="build.step==='END' && build.status==='OK'">COMPLETATA</div>
                    <div class="badge p-2 bg-danger text-light" v-if="build.step==='END' && build.status==='KO'">FALLITA</div>
                    <div class="badge p-2 bg-danger text-light" v-if="build.step!=='END' && build.status!=='PROGRESS'">INCOMPLETA</div>
                    <div class="badge p-2 bg-warning text-dark" v-if="build.step!=='END' && build.status==='PROGRESS'">IN ESECUZIONE</div>
                  </td>
                  <td><button @click="remove(build.build_id)" type="button" class="btn btn-outline-danger" v-if="build.step==='END' || build.status!=='PROGRESS'">Rimuovi</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderPage from '../app/HeaderPage.vue';
export default {
  components: { HeaderPage },
  computed: {
    builds() {
      return this.$store.state.deployment.list;
    }
  },
  methods: {
    remove(id){
      this.$store.dispatch("deployment/remove",id);
    },
    refresh() {
      this.$store.dispatch("deployment/list");
    }
  },
  created() {
      this.refresh();
  }
};
</script>