import { createRouter } from 'vue-router'

import HomePage from '../home/HomePage';
import LoginPage from '../login/LoginPage';
import ListPage from '../list/ListPage';
import DetailPage from '../list/DetailPage';

const router = createRouter({
  routes: [
    { path: '/', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/list', component: ListPage },
    { path: '/detail/:id', component: DetailPage },
    // otherwise redirect to home
    {
      // path: "*",
      path: "/:catchAll(.*)",
      name: "NotFound",
      redirect: '/',
      meta: {
        requiresAuth: false
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    if(from.path!=="/login")
      return next('/login');
    else
      return;
  }

  next();
})

export default router;