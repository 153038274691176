<template>
  <div>
    <header-page></header-page>
    <section class="jumbotron text-center">
      <div class="container">
        <h1>Pubblicazione Sito</h1>
        <p class="lead text-muted">
          Per pubblicare il sito cliccare il tasto "Avvia".
        </p>
        <p>
          <button @click="deploy()" v-if="isEnd || steps.length===0" class="btn btn-primary my-2 mr-2">Avvia una nuova compilazione</button>
          <button @click="refresh()" v-if="buildid && !isEnd" class="btn btn-success mr-2">Aggiorna</button>
          <button @click="clear()" v-if="buildid" class="btn btn-dark">Pulisci</button>
        </p>
        <div class="alert alert-warning" role="alert" v-if="buildidInProgress">
          Attenzione! C'è già una pubblicazione in corso: <b>{{buildidInProgress}}</b>
        </div>
        <div class="alert alert-success" role="alert" v-if="isEnd && !isError && steps.length>0">
          Ultima compilazione effettuata con successo!
        </div>
        <div class="alert alert-danger" role="alert" v-if="isError && steps.length>0">
          Errore durante l'ultima compilazione!
        </div>
        <div class="row">
          <div class="col-12">
            <div class="alert alert-secondary" role="alert" v-if="!isEnd && buildid && steps">
              Compilazione in corso...
            </div>
            <table class="table" v-if="buildid && steps">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">ID</th>
                  <th scope="col">Data</th>
                  <th scope="col">Step</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="step in steps" :key="step.id">
                  <th scope="row">{{ step.id }}</th>
                  <td>{{ step.build_id }}</td>
                  <td>{{ step.date }}</td>
                  <td>{{ step.step }}</td>
                  <td>{{ step.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderPage from '../app/HeaderPage.vue';
export default {
  components: { HeaderPage },
  data: function () {
    return {
      timeOutID: null
    }
  },
  computed: {
    buildid() {
      return this.$store.state.deployment.buildid;
    },
    buildidInProgress() {
      return this.$store.state.deployment.buildidInProgress;
    },
    steps() {
      return this.$store.state.deployment.steps;
    },
    isEnd() {
        let steps=this.$store.state.deployment.steps;
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].step==="END") {
                return true;
            }
        }
        return false;
    },
    isError() {
        let steps=this.$store.state.deployment.steps;
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].status==="KO") {
                return true;
            }
        }
        return false;
    }
  },
  methods: {
    deploy() {
      this.$store.dispatch("deployment/deploy");
    },
    refresh() {
      this.$store.dispatch("deployment/refresh");
    },
    clear() {
      this.$store.dispatch("deployment/clear");
    }
  },
  created() {
      this.$store.dispatch("deployment/status");
  }
};
</script>