<template>
  <header>
      <!-- Fixed navbar -->
      <nav class="navbar navbar-expand-md fixed-top navbar-light bg-light">
        <div class="navbar-brand mr-auto"><img height="50" src="@/assets/logo.png" /></div>
        <router-link to="/" class="btn btn-success my-2 my-sm-0 mr-3">Nuova compilazione</router-link>
        <router-link to="/list" class="btn btn-outline-primary my-2 my-sm-0 mr-3">Lista compilazioni</router-link>
        <router-link to="/login" class="btn btn-outline-dark my-2 my-sm-0">Logout</router-link>
      </nav>
    </header>
</template>