import { createApp } from 'vue';
//import { router } from './_helpers/index';
//import { store } from './_store/index';
import App from './App.vue';

import { createStore } from 'vuex'
import { createRouter,createWebHashHistory } from 'vue-router'

import { authentication } from './_store/authentication.module';
import { deployment } from './_store/deployment.module';

import HomePage from './home/HomePage';
import LoginPage from './login/LoginPage';
import ListPage from './list/ListPage';
import DetailPage from './list/DetailPage';

const store = createStore({
    modules: {
        authentication,
        deployment
    }
});

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
      { path: '/', component: HomePage },
      { path: '/login', component: LoginPage },
      { path: '/list', component: ListPage },
      { path: '/detail/:id', component: DetailPage },
      // otherwise redirect to home
      {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "NotFound",
        redirect: '/',
        meta: {
          requiresAuth: false
        }
      }
    ]
  });
  
  router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
  
    if (authRequired && !loggedIn) {
      if(from.path!=="/login")
        return next('/login');
      else
        return;
    }
  
    next();
  })

const app = createApp(App)

app.use(router);
app.use(store);
app.mount('#app');
