import { authHeader } from '../_helpers';
import { userService } from './users.service';

export const deployService = {
    deploy,
    getBuildSteps,
    getBuildsList,
    removeBuild
};

function deploy() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({})
    };

    return fetch(`/wpwh-build`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getBuildSteps(buildID) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            buildID: buildID
        })
    };
    return fetch(`/wpwh-status`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getBuildsList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/wpwh-list`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function removeBuild(buildID) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            buildID: buildID
        })
    };

    return fetch(`/wpwh-remove`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        try {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status !== 200) {
                    // auto logout if <> 200 response returned from api
                    userService.logout();
                    location.reload();
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
    
            return data;
        }catch(e){
            userService.logout();
            location.reload();
        }
        
    });
}