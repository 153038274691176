import { deployService } from '../_services';

export const deployment = {
    namespaced: true,
    state: {
        buildid: null,
        buildidInProgress: null,
        steps: [],
        list: [],
        detailSteps: []
    },
    actions: {
        clear({ commit }) {
            commit('clear');
        },
        deploy({ dispatch, commit }) {
            deployService.deploy()
                .then(
                    data => {
                        commit('deploy', data);
                        if(!data.inProgress) {
                            commit('deployInProgress', null);
                            dispatch('status', { buildID: data.buildID });
                        } else {
                            commit('deployInProgress', data.inProgress);
                            commit('deploy', { buildID: data.inProgress });
                            dispatch('status', { buildID: data.inProgress });
                        }
                        
                    }
                );
        },
        refresh({ commit, state }) {
            deployService.getBuildSteps(state.buildid)
                .then(
                    body => {
                        commit('status', body.steps);
                    }
                );
        },
        status({ commit, state, dispatch },data) {
            
            if(data){
                commit('deploy', data);
            }else{
                let buildIDStorage = sessionStorage.getItem("buildID");
                if(buildIDStorage){
                    commit('deploy', {buildID: buildIDStorage});
                }
            }

            
            deployService.getBuildSteps(state.buildid)
                .then(
                    body => {
                        commit('status', body.steps);
                        if(body.steps.length>0){
                            let isEnd=false;
                            for (let i = 0; i < body.steps.length; i++) {
                                if (body.steps[i].step==="END" && body.steps[i].status==="OK") {
                                    isEnd=true;
                                    break;
                                }
                            }
                            if(!isEnd){
                                setTimeout(()=>{
                                    dispatch('status');
                                },10000);
                            }
                        }
                    }
                );
        },
        detail({ commit },data) {
            deployService.getBuildSteps(data)
                .then(
                    body => commit('detail', body.steps)
                );
        },
        remove({ dispatch },data) {
            
            deployService.removeBuild(data)
                .then(
                    () => {
                        dispatch('list')
                    }
                );
        },
        list({ commit }) {
            deployService.getBuildsList()
                .then(
                    body => commit('list', body.steps)
                );
        }
    },
    mutations: {
        deploy(state, data) {
            if(data.buildID){
                sessionStorage.setItem("buildID",data.buildID);
                state.buildid = data.buildID;
            }
        },
        deployInProgress(state, buildID) {
            state.buildidInProgress = false;
            if(buildID){
                sessionStorage.setItem("buildID",buildID);
                state.buildidInProgress = buildID;
            }
        },
        status(state, data) {
            state.steps = data;
        },
        detail(state, data) {
            state.detailSteps = data;
        },
        list(state, data) {
            state.list = data;
        },
        clear(state) {
            state.steps = [];
            state.buildid = null;
            sessionStorage.removeItem("buildID");
        }
    }
}
