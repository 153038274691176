<template>
  <div>
    <header-page></header-page>
    <section class="jumbotron text-center">
      <div class="container">
        <h1>Compilazione: {{$route.params.id}}</h1>
        <p>
          <button @click="refresh()" class="btn btn-success">Aggiorna</button>
        </p>
        <div class="row">
          <div class="col-12">
            <table class="table" v-if="steps && steps.length>0">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">ID</th>
                  <th scope="col">Data</th>
                  <th scope="col">Step</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="step in steps" :key="step.id">
                  <th scope="row">{{ step.id }}</th>
                  <td>{{ step.build_id }}</td>
                  <td>{{ step.date }}</td>
                  <td>{{ step.step }}</td>
                  <td>{{ step.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderPage from '../app/HeaderPage.vue';
export default {
  components: { HeaderPage },
  computed: {
    steps() {
      return this.$store.state.deployment.detailSteps;
    }
  },
  methods: {
    refresh() {
      this.$store.dispatch("deployment/detail",this.$route.params.id);
    }
  },
  created() {
      this.$store.dispatch("deployment/detail",this.$route.params.id);
  }
};
</script>